// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
// import "bootstrap";
// import "owl.carousel";
import "slick-carousel";

// custom

function ContentHeight() {
    const ContentH = jQuery(window).height() - jQuery(".site-header").outerHeight() - jQuery(".site-footer").outerHeight();
    jQuery(".site-content").css("min-height", ContentH);
}

jQuery(document).ready(function () {
    jQuery(".close-menu").on("click", function () {
        jQuery("body").removeClass("menu-open");
    });

    jQuery(document).on("click touchstart", function (event) {
        if (jQuery(event.target).parents(".main-navigation, .call-us-block").length < 1 && !jQuery(event.target).hasClass("main-navigation")) {
            jQuery("body").removeClass("menu-open");
            jQuery("body").removeClass("number-open");
            jQuery(".main-navigation ul li").removeClass("open");
            jQuery(".menu-toggle, .phone-toggle").removeClass("toggle");
        }
    });

    jQuery(".main-navigation ul li.menu-item-has-children > a").on("click touchstart", function (event) {
        if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
            jQuery(this).parent().find("li").removeClass("open");
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });

    jQuery(".sub-menu").parent().prepend("<span class='drop-arrow'></span>");

    jQuery(".menu-toggle").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("menu-open");
        jQuery(this).toggleClass("toggle");
    });

    ContentHeight();


});

jQuery(window).load(function () {
    ContentHeight();
});

jQuery(window).resize(function () {
    ContentHeight();
});
